const releaseNotes = [
  {
    version: `2.4.3`,
    date: `2024/12/17`,
    markdownUser: `
### Fixes and improvements
- Fixed issue where other opened tabs would cause a log out despite refreshing the session
- Improved backend management of leave balance updates
`,
    markdownAdmin: `
### Fixes and improvements
- Fixed issue with leave balance log in User Directory
- Fixed issue where other opened tabs would cause a log out despite refreshing the session 
- Improved backend management of leave balance updates
- Added a warning alert when approving multiple AP invoices for the same user and month
- Added a column in AP invoices table to indicate if it has custom lines

### New features
- Leave days table now has a separate tab to access the leave balance log, allowing to consult past leave balance movements
`,
  },
  {
    version: `2.4.2`,
    date: `2024/11/12`,
    markdownUser: `
### Fixes and improvements
- Reminders now correctly display line breaks when opening them
- Added a link to Leave Request details page from the calendary day component when there is a leave request on that day
- Fixed issue where Accounts Payable comments couldn't be longer than 256 characters. Now accepting up to 500 characters in comments in general
- Fixed issue downloading Invoice files from the Attachments section of the Timesheet 

### New features
- Announcements now may include a banner
`,
    markdownAdmin: `
### Fixes and improvements
- Specific Contracts, Sales Orders and Purchase Orders can now be created with Daily Rate or Value 0. No line items will be generated for these contracts
- Fixed incorrect VAT display on Credit Note line item details
- Added Total Value column to each type of contracts in Purchase Orders table
- Added Type (Fixed Price/Time & Means) column to each contract in Purchase Orders table 
- Sales Order descriptions will now be used by default as Subject in Accounts Receivable invoices
- Updated Accounts Payable default file names when downloading in bulk (INV-MM-YY-[User name]
- Fix issue where creating a Leave Request with the status Approved would not deduct from remaining leave days
- Fixed UI validation issues with the form to create custom Invoicing Lines
- Fixed issue where checking Open-ended would not clear End-date input
- Reminders now correctly display line breaks when opening them
- Fixed issue where all countries would be offered as an option when filtering access to Announcements and Reminders, now only countries actively used in Staff Contracts will appear
- Fixed issue where Accounts Payable invoices would be marked as "Not A Match" and a system comment would be added for no reason
- Added a link to Leave Request details page from the calendary day component when there is a leave request on that day
- Fixed issue where the app would break after deleting a country
- Fixed issue where Accounts Payable comments couldn't be longer than 256 characters. Now accepting up to 500 characters in comments in general
- Fixed sorting and filter issues in several dashboards
- Task comments now correctly notify users tagged in that Task
- Added warning before deleting a user
- Fixed issue downloading Invoice files from the Attachments section of the Timesheet 
- Fixed issue cancelling completed milestone
- Fixed issue where we could still change the value of a Sales Order or Purchase Order after Milestone completion
- Added an "Created at" (uploaded on) column to Accounts Payable
- Corrected typo in pdf files generated for Credit Notes
- Fixed Period column in Invoicing Lines table, specifically the "To be Invoiced" tab to show a range of dates (dd/mm/yyyy-dd/mm/yyyy) to better reflect the milestone Period
- Now deleting Accounts Payable invoices from storage when deleting the entry

### New features
- Announcements now may include a banner
- Can now include relevant timesheets when downloading Accounts Receivable pdf files
`,
  },
  {
    version: `2.4.1.3`,
    date: `2024/10/10`,
    markdownUser: ``,
    markdownAdmin: `
### Fixes and improvements
- Fixed issue editing Accounts receivable invoice
- Fixed issue editing Credit notes
`,
  },
  {
    version: `2.4.1.2`,
    date: `2024/10/08`,
    markdownUser: ``,
    markdownAdmin: `
### Fixes and improvements
- Fixed issue with bank accounts table fetch
`,
  },
  {
    version: `2.4.1.1`,
    date: `2024/10/03`,
    markdownUser: ``,
    markdownAdmin: `
### Fixes and improvements
- Fixed issue bulk downloading timesheets where only results from the 1st page were downloaded
- Fixed AR invoice state in Timesheet details (unapproved line items were considered "To be invoiced")
`,
  },
  {
    version: `2.4.1`,
    date: `2024/10/03`,
    markdownUser: `
### Fixes and improvements
- When adding custom line in invoices, admins can now override the VAT property (users' VAT is still locked)
- Added Uploaded on property to invoice details and table pages
- Staff will now get a warning when modifying allocations already added to an invoice
- Fixed loader issue in user's timesheet details page when opening allocations modal
`,
    markdownAdmin: `
### Fixes and improvements
- Fixed backend validation error when creating/updating PO/SO/SC with both days_ordered and daily_rate
- Daily user birthday notification emails are now sent to all admins
- When adding custom line in AP invoices, admins can now override the VAT property (users' VAT is still locked)
- Available lines to invoice were added to admin dashboard KPI
- Added Uploaded on property to AP invoice details page and users AP table 
- Fixed loading issue in Edit user page
- Fixed backend validation error when creating custom credit notes
- Restored Client name in File naming template's list of macros
- Staff will now get a warning when modifying allocations already added to an invoice
- If a line item is modified in an existing AP invoice after creation, invoice will be flagged as "Not a match" to avoid auto-approval (System comment will also be added for additional clarity)
- When marking AP invoice as Paid, payment date's default value is now the due date
- Fixed issue with Download CSV where filters were not being applied
- Fixed issue in several tables when filters were applied in conjuction
- Fixed loader issue in user's timesheet details page when opening allocations modal
- Added backend validation when creating custom lines
- Fixed issues with the Mark as Paid flow in AR details

### New features
- Added Bulk payment feature allowing admins to mark AP and AR invoices as Paid or Unpaid in bulk
- Now creating Tasks/Events when Pre-approved timesheets are invoiced to client
`,
  },
  {
    version: `2.4.0.1`,
    date: `2024/09/26`,
    markdownUser: ``,
    markdownAdmin: `
### Fixes and improvements
- Fixed error handling of Value field when creating/editing Sales Order or Specific Contract
`,
  },
  {
    version: `2.4.0`,
    date: `2024/09/25`,
    markdownUser: `
### Fixes and improvements
- Files can be uploaded to Timesheet regardless of the Timesheet status. The status only impedes removing files.
- When creating a new invoice, users can now view adjustment lines that were post-timesheet approval (when a credit note was created, or custom allocations were added) and include them on the invoice

### New features
- Added Fixed Price type contracts
- Fixed price contracts generate milestone invoicing lines upon milestone completion that may be added to invoices
- Separated the Invoices module from the Timesheets module. Freelancers and companies can now submit invoices separately from their timesheet if they wish, enabling to invoice several months if needed and to have a separate table with all invoicing history (on the My Invoices page that was created).
- Users can now upload invoices with custom lines (i.e Reinvoicing of expenses, Recruitment fee, Other)
`,
    markdownAdmin: `
### Fixes and improvements
- Max decimal places of units for both AR and AP invoices is now managed directly in the invoice creation/edit
- Can now create multiple Custom Invoicing Lines for a single timesheet (to be confirmed)
- Fixed VAT disclaimer when updating an existing invoice
- Fixed display of VAT values for AR invoices
- Improved file naming template settings to allow for more customisation and added more variables
- Small UI improvements to AR invoice details page
- Files can be uploaded to Timesheet regardless of the Timesheet status. The status only impedes removing files.
- Fixed Document country restrictions to apply to user's calendar country instead of billing country
- Added Company Name column to AP table and details (company name is now mandatory for Company Staff contract's)
- Integrated AP invoices into the Task/Events module
- Added more links between pages and resources to improve navigation
- Related Client invoices (AR) will now appear on the timesheet page to show what is the status of that timesheet's allocations invoicing items
- Fixed several table filters
- Included financial KPI on our home page Admin Dashboard as well as weekly Admin summary email
- When creating a new invoice, users can now view adjustment lines that were post-timesheet approval (when a credit note was created, or custom allocations were added) and include them on the invoice

### New features
- Added Fixed Price type contracts for Staff Orders, Specific Contracts and Sales Orders
- Fixed price contracts generate milestone invoicing lines upon milestone completion that may be added to invoices
- Added Billing Units property to Staff Orders and Sales Orders to allow for billing based on days or hours (only available for Time & Means contracts)
- Separated the Invoices module from the Timesheets module. Freelancers and companies can now submit invoices separately from their timesheet if they wish, enabling to invoice several months if needed and to have a separate table with all invoicing history (on the My Invoices page that was created).
- Users can now upload invoices with custom lines (i.e Reinvoicing of expenses, Recruitment fee, Other)
- On the Admin side, we moved the user's AP approval flow from the timesheet module to the finance module 
- Added bulk download feature to timesheets table that will download both Draft and Signed timesheets
- Added Auto-approve AP button that will approve all user invoices that respond to a certain criteria
`,
  },
  {
    version: `2.3.1`,
    date: `2024/06/07`,
    markdownUser: `
### Fixes and improvements
- Fixed issue with error handling when inactive users tried to log on
- Fixed UI issue in Homepage if more than 5 Announcements were displayed
`,
    markdownAdmin: `
### Fixes and improvements
- Fixed issue when marking Accounts Payable as Paid
- Fixed Download to CSV button in Accounts Payable
- Fixed date format of line item's period property (from 24 to 2024)
- Fixed several small issues in Create Credit Note page
- Fixed issue with error handling when inactive users tried to log on
- Fixed issue deleting Invoice from the details page
- VAT disclaimer is now customizable. When invoicing private clients from outside of Belgium default belgian VAT disclaimer will be suggested. Otherwise, field is empty
- Fixed issue filtering by Staff Contract type in Purchase Order dashboard
- Fixed UI issue in Homepage if more than 5 Announcements were displayed
- Fixed issue when creating Announcements

### New features
- Added feature to merge allocation line items belonging to the same PO when creating invoices/credit notes
- Added feature to customize VAT disclaimer in invoices/credit notes
- Added check to hide/display period of line items
`,
  },
  {
    version: `2.3`,
    date: `2024/04/05`,
    markdownUser: `
### Fixes and improvements
- Allocation units can now be rounded up with up to 6 decimal cases
- Fixed issue in timesheets when accessed from Safari
`,
    markdownAdmin: `
### Fixes and improvements

- Tagging a user now generates a notification for target user
- Now creating branch backup using git archive and bundle commands
- Fixed issue filtering Purchase Orders by consultant
- Removed iban, bic/swift and vat number validation
- Fixed issue in timesheets when accessed from Safari
- Added table filter Invoice uploaded in Accounts Payable

### New features
- Added script to anonymize data in staging and local environments
- Added feature to change the way allocation units are rounded up/displayed in invoices
`,
  },
  {
    version: `2.2`,
    date: `2024/03/08`,
    markdownUser: `
### Fixes and improvements
- Fixed several issues around Leave Requests and date validation
- Training leave now accepts Weekend days 
- Added warning when leaving a Timesheet page as a user if the Timesheet submittal is overdue
- Remaining days table in Timesheet details now shows all active PO's that month regardless of allocations
- Greyed out days where there are no active PO's in Timesheet details, those days can no longer be selected

### New features
- Added ReCaptcha to login page
`,
    markdownAdmin: `
### Fixes and improvements
- Fixed issue where remaining days log would show there are no allocations while loading results
- Added Overdue KPI to Accounts Receivable
- Added Unpaid KPI to Accounts Payable
- Added partial payments feature to Accounts Payable
- Added IBAN account to Accounts Payable table (they will show with (new) when there has been a change)
- Fixed issue where manually added invoicing lines would not capture the client selected for the Invoice
- Added validation to days ordered property to not accept negative values
- Fixed issue to download individual Invoices/Credit notes
- Fixed several issues around Leave Requests and date validation
- Training leave now accepts Weekend days 
- Added warning when approving Timesheet containing more than one PO
- Added warning when leaving a Timesheet page as a user if the Timesheet submittal is overdue
- Remaining days table in Timesheet details now shows all active PO's that month regardless of allocations
- Added link to User details in PO table
- Greyed out days where there are no active PO's in Timesheet details, those days can no longer be selected
- Removed "Same as Specific Contract" feature in Add/Edit PO
- Limited number of files that can be downloaded in Bulk Downloads
- Limited number of files that can be uploaded in Documents
- Added error when user's First name and Last name are already used
- Added backend validation to country ids
- Added backend validation to account type ids
- Added backend validation to client id in Accounts Receivable and Credit note creation/edit
- Added backend validation for deducted_leave_days when creating/editing Leave Requests
- Added frontend and backend validation for IBAN, VAT number and BIC/SWIFT numbers
- User details box in Timesheet details now shows all Staff Contracts active that month
- Added UoM column to Invoicing Lines table


### New features
- Added ReCaptcha to login age
- When downloading Invoices and Credit notes you can now select a template to name the files and save your settings
`,
  },
  {
    version: `2.1`,
    date: `2024/01/17`,
    markdownUser: ``,
    markdownAdmin: `
### Fixes and improvements
- Fixed issue where due date was based on today rather than issue_date when creating accounts receivable
- Line items table column Days worked renamed to Units in Paid tab
- Removed delete row option in Invoiced tab in Invoicing lines table
- Line items will now be ordered by period
- Added total values in Create Accounts Receivable/Credit note page
- Fixed issue where invoices/credit notes created by deactivated users wouldn't be fetched

### New features
- Deprecated Invoicing Items model (now using only Line items)
- Line items now have a parent/child type association
`,
  },
  {
    version: `2.0`,
    date: `2024/01/09`,
    markdownUser: `
### Fixes and improvements
- Fixed issue with Timesheet status select 
- Users will now see post-approval adjustments made to their timesheet when uploading their invoice
`,
    markdownAdmin: `
### Fixes and improvements
- General fixes and improvements to table filters
- Fixed issue with Timesheet status select 
- Improved references system in Invoice/Credit note. Also can now edit references in Credit note
- Can now invoice clients without having a PO, only need FWC
- Added holiday calendar country to staff contract table and User Directory > Staff contracts
- Improved Overdue system in Accounts receivable with more specific date ranges and different icon colors
- Editing Accounts receivable invoices can only be done within 3 months of creation
- Fixed UI issue in User Directory
- Improved line item typology
- Fixed issue when rounding up values of invoices
- Added 'hours' option as a UoM to allocation line items
- Can no longer download invoice while editing said invoice
- Accounts receivable, can now sort by sent/paid
- Improved UX flow for consultant invoice approval. Adjustments made post-timesheet-approval can now be added to Accounts Payable, permanently deleted or carried over to next invoice approval
- Added bulk download of consultant invoice in Accounts payable
- Accounts receivable invoices and credit notes are now stored in AWS. Edit creates new versions and also keeps previous ones.
- Fixed issue where Sales Framework Contracts's clients didn't match the client in Sales Order
- Users will now see post-approval adjustments made to their timesheet when uploading their invoice
- Updated Accounts Receivable details page

### New features
- Invoice generator with credit notes and custom invoicing items
- Bulk download invoice pdfs
`,
  },
  {
    version: `1.6.6`,
    date: `2023/10/04`,
    markdownUser: `
### Fixes and improvements
- Fixed issue preventing users from deleting individual allocations in timesheet details
`,
    markdownAdmin: `
### Fixes and improvements
- Fixed issue preventing users from deleting individual allocations in timesheet details
- Fixed issue with Edit Columns feature in purchase order table
- Employee cost will no longer be sent to frontend
- Sales order and specific contract price will no longer be sent to frontend when in timesheet details
`,
  },
  {
    version: `1.6.5`,
    date: `2023/09/26`,
    markdownUser: `
### Fixes and improvements
- Added decimal days to project allocations
- Allocations Summary card has been revamped and now displays more information on the remaining days per month
- Added user warning to invoice all items in one invoice document
`,
    markdownAdmin: `
### Fixes and improvements
- Added decimal days to project allocations
- Admins can now add leave requests directly from the timesheet calendar
- Added links to leave requests from LR summary card on the timesheet calendar
- Fixed User Directory display issue
- Allocations Summary card has been revamped and now displays more information on the remaining days per month
- Update Leave Balance modal now displays the balance to be added differently
- Remaining Days log UI has been updated
- Filters on Invoicing Items now stay applied after creating invoices
- Added user warning to invoice all items in one invoice document
`,
  },
  {
    version: `1.6.4`,
    date: `2023/09/05`,
    markdownUser: `
### Fixes and improvements
- Comments now have a longer character limit (2000)
- Timesheet entries/allocations no longer need to be multiples of .25
- Fixed issue in user announcements where all posts showed as being created that same day
`,
    markdownAdmin: `
### Fixes and improvements
- Added a checkbox for user notification on timesheet status update
- Fixed wrong counts in KPI dashboard/tables for open timesheets and contracts up for extension
- Fixed Cost+VAT and Revenue+VAT values in invoicing items table
- Fixed issue with Up for extension filter in Invoicing items table
- Documents are now uploaded in groups so as to not notify user multiple times per upload
- Renamed Total Cost column to Cost+VAT in Accounts Payable table
- Added Margin(%) column to Purchase Order table
- Comments now have a longer character limit (2000)
- Timesheet entries/allocations no longer need to be multiples of .25
- Fixed issue in user announcements where all posts showed as being created that same day
`,
  },
  {
    version: `1.6.1`,
    date: `2023/07/18`,
    markdownUser: `
### Fixes and improvements
- Broadened accepted symbols for passwords
- Email notification when uploading user documents now includes document name
`,
    markdownAdmin: `
### Fixes and improvements
- Broadened accepted symbols for passwords
- Fixed issue with context menu (right click) in Purchase orders table
- Fixed sorting and filtering issues in Clients table
- Table page is now reset when applying/removing filters
- Added a link to Purchase order details in Timesheet Summary. Also added tooltip with FWC, sales order and specific contract
- Fixed issue where 0 would appear instead of FTE in Summary Modal when creating/editing Purchase order
- Email notification when uploading user documents now includes document name
- Fixed issue when creating users if there was a validation error, save button would get stuck in loading
- Improved validation of announcements to prevent errors
- Fixed issue when editing both specific contract/sales order and purchase order dates at the same time
- Can now remove Point of contact from contracts
- Fixed issue where company details would not appear when editing Company Staff contract

## New features
- Staff contract now has 2 country fields: Billing country (to calculate VAT in user invoices) and Holiday calendar (to determine what Public holidays user should see). When Freelancer or Company contracts, select them both individually. When Employee contracts, Billing country and Holiday calendar will be the same
- Added Country field to Specific Contract. This will determine which Closure days the user will see.
`,
  },
  {
    version: `1.6`,
    date: `2023/06/28`,
    markdownUser: `
### Fixes and improvements
- Check files for viruses before uploading
- File names now have stricter requirements when uploading
- Fixed issues with holidays appearing in Timesheet calendar
- Improvements to Chat/Comment module. You can now press Enter to introduce a line break and Shift+Enter to post the comment/message. Fixed issue where it was impossible to delete message despite being within the 2 minute timer
- Passwords now need to have at least 8 characters, one capital letter, one symbol and one number
- Temporary passwords are generated when creating accounts/resetting password by admins

## New features
- Timesheet files are now segregated into specific categories (Draft Timesheet, Signed Timesheet, Invoice and Other)
`,
    markdownAdmin: `
### Fixes and improvements
- Client field is now mandatory when creating Specific Contracts/Sales Orders
- When toggling subco Framework Contracts in Create Purchase Order now specifies "Invoicing done through an intermediary (Consortium Leader or Prime Contractor)" 
- Check files for viruses before uploading
- File names now have stricter requirements when uploading
- Reduced amount of personal information available on client side 
- Reduced amount of information on backend errors
- Added security measures to prevent too many login attempts or to use invalid authentication tokens
- Fixed issues on holidays module (holiday/closure day not appearing in Timesheet calendar, not being able to filter by country/type in holidays table)
- Fixed issue where Framework Contracts table was showing wrong number of results
- Fixed issue where editing a Framework Contract would display an error despite the change being applied
- Improvements to Chat/Comment module. You can now press Enter to introduce a line break and Shift+Enter to post the comment/message. Fixed issue where it was impossible to delete message despite being within the 2 minute timer
- Fixed issue in Documents module where a newly deleted file would remain in the table until a refresh occurred
- Fixed issue in User Directory where pressing "Back" would not work as expected
- Fixed issue when editing user profile where loading icon would not appear
- Fixed issue when following Admin KPI link for Open Timesheets where the filter applied would be incorrect and the number of Timesheets would not match
- Passwords now need to have at least 8 characters, one capital letter, one symbol and one number
- Temporary passwords are generated when creating accounts/resetting password by admins

## New features
- Finance tab for admins containing Accounts Receivable, Accounts Payable and Invoicing Items tables
- Timesheet files are now segregated into specific categories (Draft Timesheet, Signed Timesheet, Invoice and Other)
- Added table settings to save your preferences when reordering, adding or removing columns and filters. (remember to press save in Filters, Columns) for the changes to persist
`,
  },
  {
    version: `1.5`,
    date: `2023/04/18`,
    markdownUser: `
### Fixes and improvements
- Will now send happy birthday e-mails instead of notifications
- Improved the UI/UX of the timesheet calendar

## New features
- Will now send e-mails every 7th, 14th, 21st and 28th of every month to users who have overdue timesheets
- Will now send e-mail reminders every 1st of the month for users who still haven't submitted last month's timesheet
- Users can now request training leave in the leave request module
- Can now delete your leave requests/timesheet comments within 2 minutes of their creation
`,
    markdownAdmin: `
### Fixes and improvements
- Sales orders and specific contracts can now be reused in multiple purchase orders as long as there are enough days ordered
- Admins will no longer be notified of user birthdays, only HR
- Will now send happy birthday e-mails instead of notifications
- All table's loading speeds should be considerably faster
- Improved the UI/UX of the timesheet calendar
- Fixed issue where contracts would appear flagged as "up for extension" despite already having been handled
- Fixed issue with extension module displaying different statuses depending on the page
- Fixed issue where deleting date from "Filter results" would revert date back to 1970
- Fixed issue where reminders couldn't be deleted due to UI 
- Can now filter contracts by 'Open ended'

## New features
- Will now send e-mails every 7th, 14th, 21st and 28th of every month to users who have overdue timesheets
- Will now send e-mail reminders every 1st of the month for users who still haven't submitted last month's timesheet
- Users can now request training leave in the leave request module
- Can now delete your leave requests/timesheet comments within 2 minutes of their creation`,
  },
  {
    version: `1.4`,
    date: `2023/03/10`,
    markdownUser: `
### Fixes and improvements
- Overhaul of the timesheet and allocation system. Selection process was improved, can now select multiple days by dragging or clicking and then open allocation tab whenever ready. Can now delete allocations directly from the calendar
- Allocation modal now displays all allocations entered in days selected. Can either delete them individually or in group. For the moment, it's not possible to add allocations if the days selected already have allocations.
- Leave requests can no longer be created in the same days
- Fixed issue with comment sorting
- Fixes to changing pages in tab lists (i.e. leave requests, timesheets)
- Fixed issue with dates displayed in Announcements
- Fixed issue where leave requests without paid leave would show 0 days requested
- Users can now edit their home address and can add a secondary phone number
- Announcements and reminders now send e-mails to appropriate users
- In timesheets, leave requests and documents, uploading a file with the same name as an existing file will display an error
- Fixed display issue in Notifications dropdown when open in Announcement page
- Fixed display issue in menu buttons throughout the application
- Pre-approved timesheets no longer accept adding or removing allocations

## New features
- Pdf, png and jpg files can now be previewed directly in myThaleria
`,
    markdownAdmin: `
### Fixes and improvements
- Overhaul of the timesheet and allocation system. Selection process was improved, can now select multiple days by dragging or clicking and then open allocation tab whenever ready. Can now delete allocations directly from the calendar
- Allocation modal now displays all allocations entered in days selected. Can either delete them individually or in group. For the moment, it's not possible to add allocations if the days selected already have allocations.
- Leave requests can no longer be created in the same days
- Fixed issue with comment sorting
- Fixes to changing pages in tab lists (i.e leave requests, timesheets)
- Fixed issue with dates displayed in Announcements
- Fixed issue where leave requests without paid leave would show 0 days requested
- Removed preferred names from admin tools (now only used in welcome card and user profiles)
- Added user name on staff contract details 
- Added links to associated framework contracts, staff contracts and users in purchase order and staff contract details page
- Users can now edit their home address and can add a secondary phone number
- Announcements and reminders now send e-mails to appropriate users
- In timesheets leave requests and documents, uploading a file with the same name as an existing file will display an error
- Freelancer users will now only be able to take Leave as opposed to Unpaid Leave
- Fixed display issue on Notification dropdown when open in Announcement page
- Fixed display issue in react select menus throughout the application
- Fixed issue where clicking download or delete file in Document Manager would also select the file
- Pre-approved timesheets no longer accept adding or removing allocations
- Improved performance on contracts dashboard

## New features
- KPI dashboard will display most urgent actions admins need to take (about to expire/expired contracts, open timesheets, open leave request and open tasks) and provide links to the relevant resources
- Pdf, png and jpg files can now be previewed directly in myThaleria
- Purchase orders that are about to expire or whose remaining days are under 30 will now be flagged. Admins will need to treat each order by stating if it will be renewed or expired and providing notes `,
  },
  {
    version: `1.3.1`,
    date: `2023/02/01`,
    markdownUser: `### Fixes and improvements
- Changes requested warning message now appears on LR details page when it is in that status
- Fixed issue where you could not cancel the LR submission when it was on Changes Requested
- Fixed issue where Upload profile picture modal did not display properly on smaller screens 
- Fixed Comments issue where you could not send comment by pressing Enter when in Timesheet module
- Upload files now have size and file type validation all throughout application

### New Features
- There is now a summary below the Timesheet details with the total number of days per project and total leaves taken for users as well
- Timesheet calendar now displays different color for weekends
- You can now double click a file to download it
    `,
    markdownAdmin: `### Fixes and improvements
- Changes requested warning message now appears on LR details page when it is in that status (User)
- Fixed issue where you could not cancel the LR submission when it was on Changes Requested (User)
- Fixed issue where days remaining did not consistently show with 2 decimals (Admin)
- Fixed issue where Download button did not work to download files when on User Directory (Admin)
- Fixed issue where Upload profile picture modal did not display properly on smaller screens 
- Fixed Comments issue where you could not send comment by pressing Enter when in Timesheet module
- Upload files now have size and file type validation all throughout application
    
    ### New Features
- There is now a summary below the Timesheet details with the total number of days per project and total leaves taken for users as well (User)
- Timesheet calendar now displays different color for weekends
- You can now double click a file to download it (Users)
        `,
  },
  {
    version: `1.3`,
    date: `2023/01/30`,
    markdownUser: `### Fixes and improvements
- Improved e-mail templates design
- Notification messages are now more succinct (some had redundant text and were too long)
- Fixed server error when visiting first page after being logged out or on first attempt
- Fixed issue where notifications weren't flagged as Read despite clicking on them
- Fixed issue in timesheet calendar where leave request would display on weekends also
- Leave requests are now associated with a staff contract. Cancelling a leave request that was previously approved is now only possible if the associated contract is active.
- Fixed issue where users would receive notifications for actions they did themselves
- Improved documents management for leave requests and timesheets (you can no longer upload or delete documents on leave requests or timesheets that are approved, and users can only upload documents on certain statuses)
- In notifications page, pressing on a notification now marks it as read
- Login now expires in 1 hour for users
- Allocations now only allow 1 project per day

### New Features
- Added a button to view/hide password when logging in
- All users can now reset their own password without going through administrators ("Forgot password?" button in login page)
- Administrators can now create Leave Requests on behalf of users
- Users can now change their own preferred name
- When writing a comment, can now click Enter to upload it and Shift+Enter to add line break
- Report a bug functionality now available
    `,
    markdownAdmin: `### Fixes and improvements
- Client type now appears in Holidays table
- Fixed bugs related to access management consistency within subfolders of the documents module
- Fixed issue where upload date didn't appear when first uploading a file
- Fixed issue in timesheets table that caused applied filters to be reset when deleting a timesheet
- Table rows are now limited to 10 and size of table is fixed
- Improved e-mail templates design
- Fixed issue where purchase order table couldn't be sorted by ID
- Notification message are now more succinct (some had redundant text and were too long)
- Added padding to text when there are no staff contracts in User Directory > User info
- Fixed server error when visiting first page after being logged out or on first attempt
- Fixed issue where notifications weren't flagged as Read despite clicking on them
- Fixed buggy highlight on selected task
- Anonymized data in staging environment
- Fixed issue in timesheet calendar where leave request would display on weekends also
- Leave requests are now associated with a staff contract. Cancelling a leave request that was previously approved is now only possible if the associated contract is active.
- Fixed issue where users would receive notifications for actions they did themselves
- Days ordered now allows half days (0.5)
- Improved backend security related to managing user and admin access of the documents module
- Improved documents management for leave requests and timesheets (you can no longer upload or delete documents on leave requests or timesheets that are approved, and users can only upload documents on certain statuses)
- Changed UI for announcement and reminder access
- Fixed countries placeholder in Add Folder modal
- In notifications page, pressing on a notification now marks it as read
- User details in Leave request details now shows the contract associated with the leave request
- Login token now expires in 1 hour for users and 4 hours for admins
- Fixed issue where we could update Framework type even when already associated with a purchase order
- Allocations now only allow 1 project per day

### New Features
- Added a button to view/hide password when logging in
- All users can now reset their own password without going through administrators (Forgot password? button in login page)
- Added a comment to leave day balance update at the beginning of the year containing the previous year's leave/recuperation day balance. This should help to carry over any additional days
- Administrators can now create Leave Requests on behalf of users
- Can now create closure days in the holidays module. Can select specific sector, company and countries where it applies
- Users can now change their own preferred name
- When writing a comment, can now click Enter to upload it and Shift+Enter to add line break
- Report a bug functionality now available

    `,
  },
  {
    version: `1.2.1`,
    date: '2022/12/16',
    markdownUser: `### Fixes and improvements
- Fixed issue with table sorting
- Updated display of Changes Requested status in timesheets
- Improved notification system. Admins will now receive less notifications and instead will have access to tasks. Users will now receive a notification when a document is uploaded to their folder, timesheets or leave requests, in addition to prior ones

### New Features
- Added a dedicated page to view all notifications (bye Mr. Astley!)
`,
    markdownAdmin: `
### Fixes and improvements
- Improved the UI and the performance of the documents explorer
- Fixed issue with table sorting
- Updated display of Changes Requested status in timesheets
- Fixed the script to create timesheets every month
- Improved notification system. Admins will now receive less notifications and instead will have access to tasks. Users will now receive a notification when a document is uploaded to their folder, timesheets or leave requests, in addition to prior ones
- Allocations are now grouped by month when inspecting contract remaining days

### New Features
- Can now restrict access to files and folders within organisation/company documents, filtering out user types, contract types or country.
- Added a dedicated page to view all notifications (bye Mr. Astley!)
- Created a task management system. Certain events like submitting a timesheet or creating a comment on a leave request will create tasks where you can tag users to follow-up on those requests. You can also leave a comment giving more details about actions taken    
    `,
  },
  {
    version: `1.1.0`,
    date: '2022/11/18',
    markdownUser: `### Fixes and improvements
- Timesheets now allow from 0.25 to 16 hours per allocation
- Profile tabs are now hash enabled
- Updated list boxes to use the same component throughout the app
- Application's responsiveness was improved for mobile devices
- Fixed issue where it was impossible to delete the 0 when adding allocations
- Point of contact now displays full name in details page
- Listbox options now display in alphabetical order by default
- Created a release notes page (press on version link in the bottom-right corner)
`,
    markdownAdmin: `
### Fixes and improvements
- Price/cost/daily rate is now displayed with 2 decimals (i.e 1.00)
- Timesheets now allow from 0.25 to 16 hours per allocation
- Profile tabs are now hash enabled
- Arranged order in which tables display data. Mostly by most recently created, timesheets by period and then by user alphabetically
- Fixed issue where when choosing who can view an anouncement, the text mentioned a reminder instead of announcement
- Added back-end validation
- Cleaned up config file
- Removed all unused files
- Updated state management architecture from Redux to useContext
- Removed all unused routes and functions
- Updated modals to use the same component throughout the app
- Updated list boxes to use the same component throughout the app
- In tables when performing a context menu action filters and sort aren't reset anymore
- Application's responsiveness was improved for mobile devices
- Purchase order dates now need to fit within the associated staff contract's date
- Admins can now delete user's profile picture
- Fixed issue where half-end date option was missing from the admin's view of a user's leave request details
- Fixed issue where it was impossible to delete the 0 when adding allocations
- Point of contact now displays full name in contracts details page
- Fixed wording in "This placement is through a subcontractor" to "This placement is made as a subcontractor"
- Listbox options now display in alphabetical order by default

### New Features
- User's leave day balance is now calculated based on region (Belgium and anywhere else)
- For Belgium, users get up to 20 leave days depending on days worked the year prior. Additionally, users are rewarded 1 or 0.5 recuperation days per month (depending on hours worked per week, 40 or 38)
- For other countries users get up to 25 leave days depending on days worked that same year
- Freelancer and company users no longer have a leave day balance
- Leave day balance is now associated with a staff contract. When staff contract ends, leave day balance is no longer used and if another staff contract is created, it will have a new leave day balance created
- Can now also update leave day balance when editing staff contract
- It is now no longer possible to change staff contract's country, contract type or user
- Created a release notes page (press on version link in the bottom-right corner)
    `,
  },
  {
    version: '1.0.0',
    date: '2022/09/01',
    markdownUser: `### Initial launch`,
    markdownAdmin: `### Initial launch`,
  },
];

export default releaseNotes;
